<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">
            <buy-appointment-tickets/>
        </div>

    </ContentWrapper>
</template>
<style lang="scss">

</style>
<script>
    import BuyAppointmentTickets from "../components/BuyAppointmentTickets";

    export default {
        components: {
          BuyAppointmentTickets,
        },
    }
</script>
