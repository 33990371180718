<template>
  <div class="card card-default mt-3 animated-fast fadeInDown" :class="loading ? 'whirl' : ''">
    <div class="card-header">
      <slot name="banner"></slot>
    </div>
    <div class="card-body">

      <slot name="description"></slot>
      <template v-if="appointments_available">

        <div class="row" v-if="ticket_types.length > 1">
          <div class="col-sm-12">
            <div class="large-button-selector">
              <div v-for="ticket_type in ticket_types"
                   class="b mb-2"
                   :class="{selected: ticket_type.type === visitor_request.ticket_type.type}"
                   @click="selectType(ticket_type)">
                <b-row class="p-3">
                  <b-col>
                    <i class="fad fa-ticket mr-2"></i> {{ ticket_type.title }} - {{ ticket_type.price|currency }}€
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>

        <b-row v-if="type === 'appointment'">
          <b-col sm="12">
            <div class="form-group">
              <label>{{ $t('appointment.component.appointment_request.form.appointment.label') }}:</label>

              <b-row>
                <b-col sm="6">
                  <select v-model="dateGroup" class="form-control" required>
                    <template v-for="(a, date) in appointments">
                      <option :value="date" :key="date">
                        {{ date }}
                      </option>
                    </template>
                  </select>
                </b-col>
                <b-col sm="6">
                  <select v-model="visitor_request.appointment_id" class="form-control" required>
                    <template v-for="appointment in appointments[dateGroup]">
                      <option v-if="appointment.tickets_left > 0" :value="appointment.id" :key="appointment.id">
                        {{ $t('appointment.component.appointment_request.form.appointment.option', {date: appointment.start_at_time, tickets_left: appointment.tickets_left}) }}
                      </option>
                    </template>
                  </select>
                </b-col>
              </b-row>

              <small class="form-text text-muted">{{ $t('appointment.component.appointment_request.form.appointment.help') }}</small>
            </div>
          </b-col>
        </b-row>

        <template v-if="visitor_request.ticket_type.type === 'ticket'">
          <ticket-registration v-model="visitor_request" ref="ticketEditor"></ticket-registration>
        </template>
      </template>
      <div v-else class="alert alert-warning">
        <b-col sm="12">
          {{ $t('appointment.component.appointment_request.alert.no_appointments_available') }}
        </b-col>
      </div>

      <slot name="description-between"></slot>

      <div class="text-right" v-if="appointments_available">

        <hr>

        <payment-selector :product="visitor_request.ticket_type"
                          :quantity-preselected="1"
                          :quantity-limit="1"
                          :terms="$t('appointment.component.ticket_request.terms')"
                          :options="visitor_request"
                          :validate="onSubmit">

        </payment-selector>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentSelector from '../../../modules/Wallet/components/PaymentSelector.vue';
import Ticket from "./Ticket";
import TicketRegistration from "./Actions/TicketRegistration";

export default {
  props: ['type'],
  components: {
    TicketRegistration,
    Ticket,
    PaymentSelector,
  },
  data() {
    return {
      tickets: [],
      dateGroup: null,
      appointments_available: false,
      has_enough_balance: false,
      ticket_types: [],
      appointments: [],
      visitor_request: {
        firstname: '',
        lastname: '',
        birthdate: null,
        amount_plants_interested: 0,
        appointment_id: ''
      },
      loading: true
    }
  },
  beforeMount() {
    this.loadTickets();
  },
  methods: {
    selectType(ticket_type) {
      this.visitor_request.ticket_type = ticket_type;
      this.$forceUpdate();
    },
    groupBy(array, key) {
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]) {
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    onSubmit() {
      if (this.visitor_request.ticket_type.type === 'ticket') {
        if (!this.$refs.ticketEditor.$refs.ticket.checkValidity()) {
          this.$refs.ticketEditor.$refs.ticket.reportValidity();
          return false;
        } else {
          return true;
        }
      }

      return true;
    },
    loadTickets() {
      this.loading = true;
      this.$api.get('appointment/appointments?type=' + this.type).then(response => {
        this.appointments = this.groupBy(response.data.appointments, 'start_at_date');
        this.appointments_available = response.data.appointments.length;
        this.tickets = response.data.tickets;
        this.has_enough_balance = response.data.has_enough_balance;
        this.ticket_types = response.data.ticket_types;
        this.visitor_request.ticket_type = this.ticket_types[0];

        if (this.appointments_available) {
          this.visitor_request.appointment_id = this.appointments[Object.keys(this.appointments)[0]][0].id;
          this.visitor_request.appointment = this.appointments[Object.keys(this.appointments)[0]][0];
          this.dateGroup = Object.keys(this.appointments)[0];
        }

        this.loading = false;
      });
    }
  },
}
</script>