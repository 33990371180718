<template>
  <div>
    <div class="alert alert-danger animated-fast fadeInDown">
      {{ $t('appointment.component.appointment_request.hint_cancellation') }}
    </div>
    <div v-if="tickets.length" class="card card-default animated-fast fadeInDown">
      <div class="card-header">
        <i class="fad fa-ticket mr-2"></i> {{ $t('appointment.component.appointment_request.tickets_heading') }}
      </div>

      <div class="card-body">
        <b-row>
          <b-col lg="6">
            <div v-for="ticket in tickets" :key="ticket.label">
              <ticket :ticket="ticket"></ticket>
            </div>
          </b-col>
          <b-col lg="6">
              <b-col lg="6">
                <div class="mt-3">
                  <b>Address:</b><br/>
                  <address class="text-secondary">
                    Cannerald AG<br/>
                    Kirchgasse 30<br/>
                    3312 Fraubrunnen, Switzerland
                  </address>
                  <b-btn href="https://g.page/cannerald?share" class="mb-3" target="_blank">Show on Google-Maps</b-btn>
                </div>
              </b-col>
          </b-col>
        </b-row>
      </div>
    </div>

    <ticket-request type="appointment">
      <template v-slot:banner>
        <i class="fad fa-camera-retro mr-2"></i> {{ $t('appointment.component.appointment_request.heading') }}
      </template>
      <template v-slot:description>
        <div>
          <p>
            {{ $t('appointment.component.appointment_request.text') }}
          </p>
        </div>

        <hr>

        <h4>{{ $t('appointment.component.appointment_request.hint.heading') }}</h4>

        {{ $t('appointment.component.appointment_request.hint.text') }}

        <hr>
        <h4>{{ $t('appointment.component.appointment_request.title_invest') }}</h4>

        {{ $t('appointment.component.appointment_request.text_invest') }}

        <hr>

        <h4>{{ $t('appointment.component.appointment_request.title_bundle') }}</h4>

        <b-row>
          <!-- <b-col md="5">
             <img src="../assets/images/appointment-bundle.png" class="w-100" alt="Bundle"/>
           </b-col-->
          <b-col md="12">
            <p>{{ $t('appointment.component.appointment_request.text_bundle') }}.</p>
          </b-col>
        </b-row>

        <hr>

        <h4>{{ $t('appointment.component.appointment_request.needles.title') }}</h4>

        <ol>
          <li>{{ $t('appointment.component.appointment_request.needles.documents') }}</li>
          <li>{{ $t('appointment.component.appointment_request.needles.other') }}</li>
          <li>{{ $t('appointment.component.appointment_request.needles.return') }}</li>
          <li>{{ $t('appointment.component.appointment_request.needles.payment') }}</li>
          <li>{{ $t('appointment.component.appointment_request.needles.products') }}</li>
          <li>{{ $t('appointment.component.appointment_request.needles.more_then_6') }}</li>
          <li>{{ $t('appointment.component.appointment_request.needles.mask') }}</li>
          <li>{{ $t('appointment.component.appointment_request.needles.return') }}</li>
        </ol>

        <p>{{ $t('appointment.component.appointment_request.needles.text') }}</p>
      </template>
    </ticket-request>
  </div>
</template>
<script>
import Ticket from "./Ticket";
import TicketRequest from "./TicketRequest";

export default {
  components: {
    TicketRequest,
    Ticket
  },
  data() {
    return {
      tickets: []
    }
  },
  beforeMount() {
    this.$api.get('appointment/tickets?type=appointment').then(response => {
      this.tickets = response.data.tickets;
    });
  }
}
</script>